@import 'react-datepicker/dist/react-datepicker';
@import 'slick-carousel/slick/slick.css';
@import 'slick-carousel/slick/slick-theme.css';

/* Restrict BG Height */
.bg-search {
  max-height: 602px;
  height: 620px;
}

input {
  color: black;
}

.rc-slider-handle {
  opacity: 1;
}

.rc-slider-rail {
  background-color: #1a9bd7;
}

/* Date Picker */
.react-datepicker__input-container input {
  width: 100%;
  border: none;
  color: rgb(100 116 139);
  appearance: none;
  background-color: #fff;
  border-color: #6b7280;
  border-width: 1px;
  border-radius: 0px;
  padding-top: 0.5rem;
  padding-right: 0.75rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  font-size: 1rem;
  line-height: 1.5rem;
  --tw-shadow: 0 0 #0000;
}

.react-datepicker__input-container input::placeholder {
  color: rgb(100 116 139);
}

.react-datepicker-wrapper:first-of-type {
  border-right: solid 1px rgb(209 213 219);
}

.react-datepicker-wrapper:first-of-type {
  margin: 0 4px;
}

.filter-column .react-datepicker-wrapper:first-of-type {
  border: none;
}

/* Input Range */
.filter-column .input-range__track--active {
  background: rgb(24, 155, 215);
}

.filter-column .input-range__slider {
  background: white;
  border: rgb(24, 155, 215);
  height: 1;
  width: 1;
}

section.filter-column input {
  background-color: #252e36;
  color: white;
  border-radius: 0.375rem;
}

@media (min-width: 1280px) {
  section.filter-column input {
    height: 64px;
  }
}

@media only screen and (min-width: 1024px) {
  .lg-hidden {
    display: none;
  }

  .sm-hidden {
    display: block;
  }
}

@media only screen and (max-width: 1023px) {
  .sm-hidden {
    display: none;
  }

  .lg-hidden {
    display: block;
  }
}

section.filter-column input::placeholder {
  color: white;
}

.input-range__track--active {
  background: rgb(24, 155, 215);
}

input[type='text'] {
  border: transparent;
}

.slick-list {
  border-radius: 0.375rem;
}

.slick-prev:before {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='%23fff' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M15.75 19.5L8.25 12l7.5-7.5' /%3E%3C/svg%3E%0A");
}

.slick-next:before {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='%23fff' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M8.25 4.5l7.5 7.5-7.5 7.5' /%3E%3C/svg%3E%0A");
}